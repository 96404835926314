/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

@media(min-width: 1px) and (max-width: 1022px){
	.wrapper{width: 100%; padding: 0 30px;}

	.sec-content .desc-sechome{left: 115px;}

	.sec-aboutus .flwdev.flw-01{display: none;}
	.sec-aboutus > figure{top: -30px;}

	.sec-contact .flwdev.flw-02{margin-top: 30%;}
	.sec-contact > figure{top: -100px;}

	.sec-wedding-service .flwdev.flw-03{display: none;}
}


@media(min-width: 1px) and (max-width: 960px){
	h1{font-size: 35px;}
	h2{font-size: 35px;}
	h3{font-size: 25px;}
	h5{font-size: 14px;}
	input, textarea, select{padding: 5px 0;}

	header{position: fixed; top: 0; padding: 0 40px; min-height: 70px;}
	header .btn-burg-menu{
		display: block; width: 40px; height: 25px; position: absolute;top: 20px;right: 50px;z-index: 20;
		span{
			position: absolute; right: 0; top: 0; background: #fff; height: 2px; width: 28px;@include transition(0.3s width)
			&:nth-child(2){top: 11px; opacity: 1;}
			&:last-child{top: 21px;}
			&:before{content: ""; position: absolute; top: 0; right: 0; bottom: 0; width: 0; @include transition(0.3s all); background: #b49d73;}
		}
		/*&:hover{
			span{
				&:first-child{width: 30px;}
				&:nth-child(2){width: 36px;}
				&:last-child{width: 20px;}
				&:before{width: 100%;}
			}
		}*/
		&.remove{
			span{
				&:before{width: 100%;}
				&:first-child, &:last-child{width: 30px; top: 10px;}
				/*&:nth-child(2){opacity: 0;}*/
			}
		}
	}
	header.float{
		span{background: #b49d73;}
		.logo{display: none !important;}
		.logo-float{display: block !important; opacity: 1 !important;}
	}
	/*header .logo{display: none;}*/
	/*header .logo-float{display: block; padding-top: 20px;}*/

	header .nav_right{background: #ffffff; position: fixed; top: 0; left: 0; right: 0; overflow-y: auto; min-height: 100vh; display: none;}
	header .nav_right a{color: #333; font-weight: 700;}
	header .nav_right a:hover{color: #b49d73;}
	header .nav_right .nav_header{@include fullfloat; padding-top: 110px; padding-bottom: 100px;}
	header .nav_right .nav_header li{@include fullfloat; line-height: 30px; height: auto; padding: 15px 0;}
	header .nav_right .nav_header li a{font-size: 24px; text-align: center; @include fullfloat;}

	header .nav_right .nav_lang{display: none;}

	header .nav_right .nav_sosmed{display: none;}
	header .nav_right .nav_sosmed_float{display: block; @include fullfloat; text-align: center; padding: 50px 0;}
	header .nav_right .nav_sosmed a, header .nav_right .nav_sosmed_float a{display: inline-block; float: none;}

	.btn-oval{padding: 0 35px; height: 40px; line-height: 40px;}
	.btn-oval.btn-pink, .btn-oval.btn-pink2{font-size: 14px;}
	.btn-oval.btn-loadmore{font-size: 14px; padding: 0 30px;}
	.lineh{width: 100px;}
	.border-white{border-width: 4px;}
	.title-content{margin-bottom: 30px;}
	.title-content h1{margin-bottom: 10px;}
	.title-content h4{margin-bottom: 10px;}
	.title-content h4.caps-detail{font-size: 20px; line-height: 30px; margin-top: 20px; margin-bottom: 20px;}
	.title-content span{font-size: 12px;}
	.box-sosmed-lg a{font-size: 11px; line-height: 16px;}
	.box-sosmed-lg a figure{width: 50px; height: 50px; margin-bottom: 5px;}
	.icwp.ic-fb-lg, .icwp.ic-ig-lg, .icwp.ic-tw-lg, .icwp.ic-gplus-lg{width: 34px; height: 26px; background-size: 100% 200%;}
	.icwp.ic-date{width: 25px; height: 25px; background-size: cover;}

	.slidehome .list-slide{min-height: 766px;}
	.slidehome .list-slide figure img{height: 766px;}
	.slidehome .list-slide .desc{font-size: 28px;}
	.slidehome .list-slide .desc .logo-lg img{width: 320px;}
	.slidehome .sc-bottom{display: none;}

	.sec-content{padding-top: 50px; min-height: auto;}
	.sec-content .desc-sechome h2{margin-bottom: 20px;}
	.sec-content .desc-sechome .quotes{font-size: 22px; margin-bottom: 20px;}

	.sec-aboutus > figure{left: 0;}
	.sec-aboutus > figure img{height: 500px;}
	.sec-aboutus .rings img{width: 260px;}
	.sec-aboutus .desc-sechome{width: 550px;}

	.sec-contact > figure{top: -60px;}
	.sec-contact > figure img{height: 500px;}
	.sec-contact .desc-sechome{width: 550px;}

	.sec-wedding-service .content-wedding .box-weddingservice{margin-top: 20px;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding{margin: 0 1%; width: 49%;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding .title-thumb-wedding h2{font-size: 30px; line-height: 35px;}
	.sec-wedding-service .content-wedding .box-weddingservice .list-wedding .title-thumb-wedding .line-v{bottom: 10px;}

	.sec-ourvenue{padding: 0;}
	.sec-ourvenue .wrapper, .our-address .box-ouraddress, .our-address .box-ouraddress .wrapper{padding: 0; width: 100%;}
	.sec-ourvenue .inner-ourvenue .slide-venue{width: 100%;}
	.sec-ourvenue .inner-ourvenue .slide-venue .slidervenue .list-slide{height: 480px;}
	.sec-ourvenue .inner-ourvenue .slide-venue .slidervenue .list-slide figure img{height: 480px;}
	.sec-ourvenue .inner-ourvenue .desc-venue{position: relative; top: auto;right: auto;width: 100%; padding: 27px 67px 50px; @include fullfloat;}
	.sec-ourvenue .inner-ourvenue .desc-venue h2 .line-v{height: 148px;}
	.sec-ourvenue .inner-ourvenue .desc-venue .vendor-partner .box-vendor .list-vendor{width: 22.5%; margin: 0 3.333333333% 10px 0;}
	.sec-ourvenue .inner-ourvenue .desc-venue .vendor-partner .box-vendor .list-vendor:nth-child(3n + 2){margin-right: 3.3333333333%;}
	.sec-ourvenue .inner-ourvenue .desc-venue .vendor-partner .box-vendor .list-vendor:nth-child(3n){margin-right: 3.3333333333%;}
	.sec-ourvenue .inner-ourvenue .desc-venue .vendor-partner .box-vendor .list-vendor:nth-child(4n){margin-right: 0;}

	.our-address .box-ouraddress{top: 0;}
	.our-address .box-ouraddress .inner-ouraddress .right .career-icon{width: auto;}
	.our-address .box-ouraddress .inner-ouraddress .right .career-icon img{width: 88px;}
	.our-address .box-ouraddress .inner-ouraddress .right .content-careerhome h3{left: 115px; top: -95px;}
	.our-address .box-ouraddress .inner-ouraddress .right .content-careerhome .box-sosmed-lg{bottom: 60px; margin-top: 0;}
	.our-address .box-ouraddress .inner-ouraddress .right .content-careerhome .box-sosmed-lg a{width: 11%;}

	.aboutbox{padding-top: 30px;}
	.aboutbox h1{margin-bottom: 15px;}
	.aboutbox .subtitle{font-size: 20px; line-height: 30px; margin-bottom: 20px;}
	.aboutbox .lineh{margin-bottom: 30px;}
	.aboutbox .rings{display: none;}
	.aboutbox .box-three-ikk{@include fullfloat; margin: 40px 0 0;}
	.aboutbox .box-three-ikk .list{height: 60px;}
	.aboutbox .box-three-ikk .list .f-lg{font-size: 60px;}
	.aboutbox .box-three-ikk .list .f-right{font-size: 25px; padding-left: 65px;}
	.aboutbox .box-three-ikk .list:first-child .f-right{padding-left: 40px;}
	.aboutbox .about-quotes{margin-top: 100px;}
	.aboutbox .flw-left-gallery{display: none;}
	.aboutbox .flw-left-service{display: none;}

	.about-quotes .text-quotes{margin-top: 30px;font-size: 22px; line-height: 32px;}
	.about-quotes .auth-text{margin-top: 40px;}

	.about-story{padding-bottom: 40px;}
	.about-story .flw-right{right: -20px;}
	.about-story .flw-right img{width: 220px;}
	.about-story .box-slide-story{width: 26.64%; position: absolute; left: 40px; top: 280px; z-index: 10;}
	.about-story .box-slide-story .navsliderstory .slick-list{max-height: 365px !important; padding: 30px 0 !important;}
	.about-story .box-slide-story .navsliderstory .list-story .a-list{padding-left: 20px; padding-right: 20px; text-align: left; padding-bottom: 25px;}
	.about-story .box-slide-story .navsliderstory .list-story .a-list:before{right: auto; left: 0; @include transform(translateY(0)); top: 8px;}
	.about-story .box-slide-story .navsliderstory .list-story .a-list h5{line-height: 20px;}
	.about-story .box-slide-story .navsliderstory .list-story .a-list .a-active h1{font-size: 14px; font-weight: 900; line-height: 20px; color: #f9c7cb;}
	.about-story .box-slide-story .navsliderstory .list-story .a-list .a-active h3{font-size: 14px; font-weight: 900; line-height: 20px; color: #f9c7cb;}
	.about-story .box-slide-story .navsliderstory .list-story.slick-current .a-list:before{width: 7px; background: #999; right: -4px;}
	.about-story .box-slide-story .navsliderstory .list-story.slick-current:hover .a-list:before{width: 7px;}
	.about-story .box-slide-story .navsliderstory .list-story:hover .a-list:before{width: 7px;}
	.about-story .box-slider-story-desc{width: 100%; min-height: 520px;}
	.about-story .box-slider-story-desc .list{padding-left: 240px;}
	.about-story .box-slider-story-desc .desc{padding-top: 30px;}
	.about-story .box-slider-story-desc .desc p{font-size: 16px; line-height: 26px;}
	.about-story .box-slider-story-desc .desc .title-story{display: block; position: absolute; top: 11px; left: 8%; text-align: right;}
	.about-story .box-slider-story-desc .desc .title-story h1{line-height: 40px;}
	.about-story .box-slider-story-desc .desc .title-story h3{line-height: 32px;}

	.philosophy{padding-bottom: 50px;}
	.philosophy .box-two.visi{margin-top: 70px;}
	.philosophy .quotes-philosophy h1{font-size: 40px;}
	.philosophy .quotes-philosophy h2{font-size: 30px;}
	.philosophy .box-two.visi .list{width: 100%; padding: 0; margin-bottom: 20px;}
	.philosophy .box-two.visi .list h5{text-align: left; margin-bottom: 15px;}
	.philosophy .box-two.visi .list ul li{margin-bottom: 10px; padding-left: 0;}
	.philosophy .box-two.visi .list ul li:before{display: none;}
	.philosophy .box-two.visi .list .mphilosophy h4{margin-bottom: 20px; text-align: left; font-weight: 500;}

	.our-team{padding-bottom: 100px;}
	.our-team .flw-left img{width: 200px; margin-top: 65%;}
	.our-team .title-content{margin-bottom: 10px;}
	.our-team .title-content h4{font-size: 20px; line-height: 34px; margin-bottom: 20px;}
	.our-team p.filsof{margin-bottom: 60px;}
	.our-team .about-quotes .text-quotes{margin-top: 20px;width: 480px;}

	.box-service{margin-top: 50px;}
	.box-service .list-service{margin-bottom: 10px;}
	.box-service .list-service .service-desc{padding-right: 20px;}
	.box-service .list-service .service-desc h1{font-size: 60px; line-height: 60px; margin-bottom: 10px;}
	.box-service .list-service .service-desc h3{font-size: 30px; line-height: 30px; margin-bottom: 20px;}
	.box-service .list-service:nth-child(odd) .service-img{padding-right: 20px;}
	.box-service .list-service:nth-child(odd) .service-desc{padding-left: 20px;}
	.box-service .list-service .service-img{padding-left: 20px;}

	.box-exvenue .list-venue{width: 48.75%; margin-right: 2%;}
	.box-exvenue .list-venue:nth-child(3n){margin-right: 2%;}
	.box-exvenue .list-venue:nth-child(2n){margin-right: 0;}
	/*.box-exvenue .list-venue:nth-child(n + 3){display: none;}*/
	/*.box-exvenue .list-venue figure{height: 250px;}*/
	.box-exvenue .list-venue figure img{min-height: 100%; max-width: none; min-width: 100%;}
	.box-exvenue .list-venue .box-desc{padding: 30px;}
	.box-exvenue .list-venue .box-desc .link-exvenue{right: 30px;}

	.explore-venue{padding-bottom: 100px;}

	.coming-soon{height: auto;}
	.coming-soon .left{width: 100%; position: relative; top: auto; bottom: auto; left: auto;}
	.coming-soon .left .content-cs{position: relative; top: auto; left: auto; right: auto; @include transform(translateY(0));}
	.coming-soon .left .content-cs h1{font-size: 35px; margin-bottom: 25px;}
	.coming-soon .left .content-cs .flw-right-cs{display: none;}

	.coming-soon .right{width: 100%; padding: 0 40px;}
	.coming-soon .right .slidercs .list-slide{min-height: auto; height: auto;}
	.coming-soon .right .slidercs .list-slide figure{height: 290px;}
	.coming-soon .right .slidercs .list-slide .desc{position: relative; bottom: auto; left: auto; right: auto; top: -58px; width: 90%; margin: auto; padding-top: 20px; padding-bottom: 20px;}
	.coming-soon .right .slidercs .list-slide .desc h1 .line-v{height: 148px;}
	.coming-soon .box-partner-cs{position: relative; bottom: auto; left: auto; @include fullfloat; padding: 0 40px 40px;}

	.detailsection{padding-top: 40px;}
	.detailsection .flw-right-gallery{display: none;}

	.box-detail-venue{margin-top: 30px; margin-bottom: 50px;}

	.venue-location{margin-top: 50px; @include fullfloat; margin-bottom: 0;}
	.venue-location .ven-left{@include fullfloat;}
	.venue-location .ven-left .mapscontact{height: 518px;}
	.venue-location .ven-right{position: relative; top: -100px; left: auto;right: auto; width: 88%; margin: auto; clear: both; padding: 40px 60px 20px;}
	.venue-location .ven-right h2{margin-bottom: 20px; line-height: 40px;}
	.venue-location .ven-right h2 .line-v{height: 140px;}
	.venue-location .ven-right p{margin-bottom: 20px;}

	.aboutbox.venuegallery{padding-bottom: 120px;}
	.aboutbox.venuegallery .btn-inqury{top: 95px; right: 40px;}

	.box-gallery .list-gallery{width: 47.954%; margin-right: 4.056%;}
	.box-gallery .list-gallery:nth-child(3n){margin-right: 4.056%;}
	.box-gallery .list-gallery:nth-child(2n){margin-right: 0; float: right;}
	/*.box-gallery .list-gallery figure{height: 250px; overflow: hidden;}*/


	.box-gallery-detail .box-slide-gallery .slick-prev, .box-gallery-detail .box-slide-gallery .slick-next{z-index: 2;}
	.box-gallery-detail .box-slide-gallery .slick-prev{left: 15px}
	.box-gallery-detail .box-slide-gallery .slick-next{right: 15px}

	.box-filter{margin-bottom: 50px;}
	.box-filter select{width: 100%; padding-left: 10px;font-size: 14px; padding-right: 34px; background-position: 108% 50%;}
	.box-filter .list-filter-gallery.svpartner{width: 22.87%;}
	.box-filter .list-filter-gallery.svenue{width: 15.46%;}
	.box-filter .list-filter-gallery.swtheme{width: 22.65%;}
	.box-filter .list-filter-gallery.sphoto{width: 20.44%;}
	.box-filter .list-filter-gallery.search-filter{width: 17.67%; padding-top: 30px;}

	.popup-gallery{overflow-y: auto; height: 100%;}
	.popup-gallery .img-large .list-slide{height: auto; min-height: auto;}
	.popup-gallery .img-large .list-slide figure{height: 100%}
	.popup-gallery .img-large .list-slide iframe{height: 450px;}
	.popup-gallery .img-large .list-slide .caps-large{width: 100%; padding-bottom: 20px; position: relative;}
	.popup-gallery .box-thumb-slider{width: 100%; position: relative; padding: 20px 40px;}
	.popup-gallery .box-thumb-slider .caption-slide{font-size: 16px; line-height: 24px; margin-bottom: 25px;}
	.popup-gallery .box-thumb-slider .list-slide figure img{height: 100px;}

	.aboutbox.vendorgallery{margin-bottom: 0;}
	.box-faq{font-size: 14px;}
	.box-faq .list-faq{padding: 20px 0;}
	.box-faq .list-faq .answr{padding-top: 15px;}
	.box-faq .list-faq .qst{font-size: 16px;}
	.box-faq .list-faq.active .qst{font-size: 18px;}
	.box-faq .list-faq:hover .qst{color: #000000;}
	.box-faq .list-faq:hover:after{width: 0; left: 50%;}
	.box-faq .list-faq.active .qst{color: #eb4e5e;}
	.box-faq .list-faq.active:after{width: 100%; left: 0;}
	.faq-content{padding-top: 30px; padding-bottom: 30px;}
	.faq-content .wrapper{width: 700px;}
	.faq-content .box-faq{margin-top: 60px;}
	.faq-content .btn-inqury{top: 80px;}
	.faq-content .flw-left-faq{display: none;}

	.contact-content .wrapper{width: 700px; max-width: 100%;}
	.contact-content .flw-contact-right{display: none;}
	.contact-content .flw-contact-left{display: none;}

	.box-follow-contact{margin-top: 80px;}
	.banner-thankyou{min-height: 90vh;}
	.box-form .row label{font-size: 14px;}

	.box-contact-right .inner{padding-left: 55px;width: 550px;}
	.box-contact-right .inner h3{padding-left: 0;}
	.box-contact-right .inner .line-v{left: 32px;}
	.box-contact-right .inner .box-form input[type="text"], .box-contact-right .inner .box-form input[type="email"], .box-contact-right .inner .box-form textarea{font-size: 14px;}

	.box-wa {
		right: 30px;
		a {
			font-size: 13px;
			i {width: 24px !important; height: 24px !important; background-size: 100%;}
		}
		&.absSroll {
			bottom: 70px;
		}
	}
	@keyframes bounching {
	    0% { bottom: 65px; }
	    35% { bottom: 70px; }
	    65% { bottom: 65px; }
	    100% { bottom: 70px; }
	}

	footer{font-size: 12px; min-height: 60px; line-height: 60px;}
}


@media(min-width: 1px) and (max-width: 780px){
	.sec-content{padding-top: 0;}

	.sec-aboutus .desc-sechome{top: 20px;}
	.sec-aboutus > figure{left: -50px; top: 0;}
	.sec-aboutus .rings{margin-top: 41%; right: 11.19%;}

	.sec-contact .flwdev.flw-02 img{width: 180px;}
	.sec-contact > figure{right: -60px;}
	.sec-contact > figure img{height: 400px;}

	.sec-wedding-service{
	 	.content-wedding{
	 		.box-weddingserviceone{
	 			.left, .right{width: 100%}
	 			.left{
	 				figure{
	 					text-align: center;
	 				}
	 			}
	 			.right{padding-top: 20px;}
	 		}
	 	}
	}
}